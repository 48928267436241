.contact-form {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f8f8f8;
    border-radius: 20px;
  }
  
  .submit-button {
    margin-top: 20px;
  }