.combined-wrapper {
    padding: 0;
    background: linear-gradient(135deg, #0F2027 1%, #203A43 50%, #2C5364 100%);
    height: 175px;
    position: relative;
    width: 100%;
  }
  .combined {
    color: #fff;
    width: 316px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .combined-shape {
    position: relative;
  }
  .combined-shape > * {
    width: 50%;
    height: 64px;
    position: absolute;
    overflow: hidden;
  }
  .combined-shape > * > * {
    width: 100%;
    height: 100%;
    background: #fff;
  }
  .combined-shape .shape-left {
    left: 0;
  }
  .combined-shape .shape-right {
    right: 0;
  }
  .combined .title {
    font-size: 58px;
    letter-spacing: 8px;
    position: absolute;
    width: 300px;
    overflow: hidden;
    text-align: center;
  }
  .combined-bar {
    width: 100%;
    height: 2px;
    background: #fff;
    position: absolute;
    top: 70px;
  }
  .combined .content {
    font-size: 15px;
    text-align: center;
    position: absolute;
    top: 78px;
    left: 0;
    right: 0;
  }